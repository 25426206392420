import React from 'react'
import WhyUs from '../components/WhyUs'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import Howitworks from '../components/Howitworks'
import GreenScroll from "../components/GreenScroll"
import DownloadApp from '../components/DownloadApp'
import MeetOurTeam from '../components/MeetOurTeam'
import WasteIntoValue from '../components/WasteIntoValue'
import ScrapCollection from '../components/ScrapCollections'
import CommitmentToQuality from '../components/CommitmentToQuality'

const Home = () => {
  return (
    <div className='bg-white'>
    {/* <GreenScroll/> */}
    <Banner id='home'/>
    <WasteIntoValue/>
    <ScrapCollection id='collection'/>
    <Howitworks id='process'/>
    <CommitmentToQuality/>
    <WhyUs id='whyUs'/>
    <MeetOurTeam/>
    <DownloadApp/>
    <Footer/>
    </div>
  )
}

export default Home