import React from "react";
import Home from './Pages/Home'
import Error from "./components/Error";
import Header from "./components/Header";
import { Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <div className="!pb-0 md:p-8 bg-slate-50">
      <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
