import { Link } from 'react-scroll'; 
import Logo from '../assets/logo.png';
import { MdMenu } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';


const navItems = [
  { name: 'Home', id: "home" },
  { name: 'Collection', id: 'collection' },
  { name: 'Our Process', id: 'process' },
  { name: 'Why Us', id: 'whyUs' },
];

const Header = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (open) {
        setOpen(false);
      }};
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [open]);

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, [location]);


  return (
    <div className='w-full bg-white  font-thicccboiMedium py-6 px-4 md:sticky z-50 top-0 '>
      <div className="flex justify-between items-center flex-wrap container mx-auto">
      
      <div>
        <img src={Logo} alt="logo" className='max-w-48' />
      </div>

      <div className='text-4xl cursor-pointer md:hidden absolute top-5 z-10 right-4' onClick={() => setOpen(!open)}>
        {open ? <IoCloseSharp/> : <MdMenu/> }
      </div>

    
      <ul className='md:flex hidden justify-end space-x-10'>
        {navItems.map(item => (
          <li key={item.id} className='font-thicccboiMedium text-lg uppercase'>
            <Link to={item.id} smooth={true} duration={500} className='cursor-pointer' offset={-100}>
              {item.name}
            </Link>
          </li>
        ))}
      </ul>

      <div>
        <button className='border-green border rounded-2xl px-6 py-2 md:block hidden text-lg hover:shadow ease-in-out duration-300 text-green'>Donwload Now</button>
      </div>


      <div className={`absolute top-20 left-0 w-full h-screen bg-white z-50 transition-transform duration-300 ${open ? 'translate-x-0' : '-translate-x-full'} md:hidden`}>
        <ul className='pt-10'>
         {navItems.map(item => (
           <li key={item.id} className='p-3 text-lg text-center '>
             <Link to={item.id} smooth={true} duration={500} className='cursor-pointer' activeClass="text-green">
               {item.name}
             </Link>
           </li>
         ))}
         
         <div className='flex justify-center'>
        <button className='border border-black rounded-full px-6 py-2.5'>Donwload Now</button>
      </div>
       </ul>
       </div>

       </div>
    </div>
  );
};

export default Header;
