import React from 'react'
import { FaRecycle } from 'react-icons/fa';
import { FiArchive } from 'react-icons/fi';
import { RiRecycleLine} from 'react-icons/ri';
import { GiEarthAmerica } from 'react-icons/gi';

const services = [
    {icon: <FaRecycle/>, title: "Metal & Material Recycling", desc: "Maximize the value of your scrap metals with state-of-the-art recycling techniques."},
    {icon: <FiArchive/>, title: "E-Waste Management", desc: "Dispose of electronics responsibly with advanced e-waste processing solutions."},
    {icon: <RiRecycleLine/>, title: "Plastic Waste Solutions", desc: "Targeted strategies to recycle and repurpose plastic waste sustainably."},
    {icon: <GiEarthAmerica/>, title: "Paper & Cardboard Recycling", desc: "Rejuvenate used paper and cardboard for a cleaner and greener future."},
]

const WasteIntoValue = () => {
  return (
    <div className='container mx-auto p-6  my-10 md:my-28'>
        <div className='md:w-2/4 mx-auto text-center'> 
           <h1 className='text-3xl font-semibold'>Buy and Sell Scrap Easily</h1>
            <p className='my-5 text-gray-600 text-bold font-thicccboiMedium'>Effortless Scrap Trading for Buyers and Sellers. Convert Your Scrap into Cash Quickly and Easily..</p>
        </div>

        <div className="flex justify-around gap-10 md:flex-row flex-col items-center mt-10">
        {services.map((service, index) => (
            <div key={index} className='rounded-md p-5 shadow-sm md:h-80 '>
                <div className="bg-white p-4 w-max rounded-full text-4xl shadow">
                    {service.icon}
                </div>
                <h1 className="text-lg font-semibold my-5 font-thicccboiMedium">{service.title}</h1>
                <p className="text-gray-600 font-thicccboiMedium">{service.desc}</p>
            </div>
        ))}
        </div>
    </div>
  )
}

export default WasteIntoValue
