import React from 'react'
import { BiMap } from 'react-icons/bi';
import {MdElectricCar}  from 'react-icons/md'
import ScrapRecycle from '../assets/scrap.recycle.webp'
import { FaGasPump, FaBatteryFull, FaClock, FaMapMarkerAlt } from 'react-icons/fa';


const keyFeatures = [
    {
      title: "24/7 Support",
      description: "Round-the-clock assistance available.",
      icon: <FaClock />,
    },
    {
      title: "Easy Scrap Sorting",
      description: "Convenient sorting services included.",
      icon: <MdElectricCar />,
    },
    {
      title: "Reduce Costs",
      description: "Lower your waste disposal expenses.",
      icon: <FaGasPump />,
    },
    {
      title: "Extended Coverage",
      description: "Reach up to 100-120 km for collections.",
      icon: <FaMapMarkerAlt />,
    },
  ];

const ScrapLastMile = ({id}) => {
  return (
    <div id={id} className='md:my-20 my-10 py-10 px-6'>
        <div className="container mx-auto flex md:flex-row flex-col gap-10 justify-between text-center md:text-start">

            <div className='relative md:w-2/4 rounded-xl'>
                <img src='https://www.acorecycling.com/wp-content/uploads/2022/02/iiii.jpg' className='object-cover w-full h-full rounded-xl' alt="scrap recycling" />
            <div className="absolute inset-0 bg-gradient-to-b rounded-xl from-black/60 to-transparent"></div>
                <div className='absolute top-10 md:w-3/4  px-10 left-0 right-0 text-white'>
                    <h1 className='text-3xl mb-2 font-semibold'>Eco-Friendly Scrap Collection</h1>
                    <h3 className='md:text-lg'>Turn waste into wealth—join as a vendor today!</h3>
                </div>
            </div>

            <div className='md:w-2/5'>
                <h1 className='text-3xl font-semibold'>ScrapCart Collections</h1>
                <h4 className='mt-5 font-thicccboiMedium text-gray-600 font-bold'>Revolutionizing scrap trading with efficient, eco-friendly services. Enjoy reliable pickups and seamless tracking for a sustainable experience.</h4>
                <div className='flex gap-5 mt-7 md:justify-start justify-center items-center flex-wrap'>
                    {keyFeatures.map((feature, index)=>(
                        <div className='w-2/5 text-center bg-gray-50 md:p-3 h-44 shadow-sm cursor-pointer hover:bg-green-100 flex flex-col rounded-md' key={index}>
                                <span className="text-3xl text-black mx-auto">{feature.icon}</span>
                                <h3 className='my-3 text-base font-bold font-thicccboiMedium'>{feature.title}</h3>
                                <p className='text-gray-600 text-sm font-thicccboiMedium'>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    </div>
  )
}

export default ScrapLastMile
