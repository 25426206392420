import React from 'react'
import { Link } from 'react-router-dom'

const HowItWorks = [
    {stepNum: 'Step 1', firstStep: 'Post, Search and Connect', desc: "You have access to all the featured. You can now take that vacation or discover a new town."},
    {stepNum: 'Step 2', firstStep: 'Get and Post a Offer', desc: "To schedule a pickup or sell your scrap, sign up for an account."},
    {stepNum: 'Step 3', firstStep: 'Create a profile', desc: "Fill out your profile to get started."},
]
const Howitworks = ({id}) => {
  return (
    <div id={id} className='container md:my-20 py-24 rounded-md shadow my-10 mx-auto p-6'>
        <div className='md:w-2/4 mx-auto text-center'>
            <h1 className='text-3xl font-semibold'>How App Works</h1>
            <p className='text-gray-600 font-semibold mt-5 font-thicccboiMedium'>Buy and sell scrap effortlessly with Scrap Solutions. From selection to scheduling and instant payments, our process is hassle-free. Get started today!</p>
        </div>

        <div className="flex md:justify-around md:mt-16 mt-10  gap-10 md:flex-row flex-col">
            {HowItWorks.map((item, index)=>(
                <div className='pt-5 p-6 md:w-1/4'>
                    <h4 className='font-thicccboiMedium mb-3 font-semibold text-lg'>{item.stepNum}</h4>
                <div key={index} className='md:border-t-4 border-green md:border-l-0 border-l-4 p-3'>
                    <h3 className='my-3 text-xl font-bold'>{item.firstStep}</h3>
                    <p className='text-gray-600 text-base font-thicccboiMedium'>{item.desc}</p>
                </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Howitworks
