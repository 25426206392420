import React from 'react'
import Logo from '../assets/logo.png'
import { Link } from 'react-router-dom'
import { IoIosSend } from "react-icons/io";
import { FaEnvelope, FaPhoneAlt, FaLinkedin } from 'react-icons/fa';

const footerData = {
    about: {
        aboutUs: "About Scrap Cart",
        description: "Simplify Scrap Selling – Select Your Scrap Type, Schedule a Convenient Pickup, and Our Trusted Vendors Will Come to You with Instant Payments!"
    },
    quickLinks: {
        title: "Quick Links",
        links:[
            {name: "Home", link: "/"},
            {name: "Our Process", link: "/"},
            {name: "Collection", link: "/"},
            {name: "Why Us",link: "/"},
            {name: "How we Works",link: "/"}
        ]
    },
    contactInfo:{
        title: "Contact Info",
        contactDetail:[
            {icon: <FaEnvelope/>, link: 'mailTo:scrapcart.pk@gmail.com', heading: 'scrapcart.pk@gmail.com'},
            {icon: <FaPhoneAlt/>, link: 'tel:03225081772', heading: '03225081772'},
            {icon: <FaLinkedin/>, link: 'https://www.linkedin.com/feed', heading: 'Follow us on Linkedin'}
        ]
    },
    SubscribeNow: {
        heading: "Subscribe Now",
        description: "Subscribe to our newsletter and never miss updates on the latest scrap deals, collection schedules, and eco-friendly tips. Join our community today!"
    },
}
console.log(footerData.quickLinks.links);

const Footer = () => {
  return (
    <footer className='bg-gray-50  md:pt-16 p-5'>
        <div className="container mx-auto font-thicccboiMedium">
                            {/* <img className='w-72' src={Logo} alt="Logo" /> */}
                <div className="flex md:justify-between justify-center p-6 flex-wrap gap-10 mt-5">
                        {/* about us */}
                        <div className='md:w-1/4 w-full text-sm text-gray-600 md:h-60'>
                            <h1 className='text-xl font-semibold mb-2 text-black'>{footerData.about.aboutUs}</h1>
                            <p className=''>{footerData.about.description}</p>
                        </div>
                        {/* quick links */}
                        <div className='md:w-1/6  w-full text-sm text-gray-600 md:h-60'>
                            <h1 className='text-xl font-semibold mb-2 text-black'>{footerData.quickLinks.title}</h1>
                            {footerData.quickLinks.links.map((item, index)=>(
                                <div key={index}>
                                    <p className='my-3'>
                                        <Link to={item.link}>{item.name}</Link>
                                    </p>
                                </div>
                            ))}
                        </div>
                         {/* contact info */}
                         <div className='md:w-1/6  w-full text-sm text-gray-600 md:h-60 '>
                            <h1 className='text-xl font-semibold mb-2 text-black'>{footerData.contactInfo.title}</h1>
                            {footerData.contactInfo.contactDetail.map((item, index)=>(
                                <div key={index}>
                                    <p className='flex gap-5 mt-3'>
                                        <span className='p-2 text-white rounded-full block bg-black'>{item.icon}</span>
                                        <a href={item.link}>{item.heading}</a>
                                    </p>
                                </div>
                            ))}
                        </div>

                         {/* Subscribe Now*/}
                         <div className='md:w-1/5 w-full text-sm text-gray-600 md:h-60'>
                            <h1 className='text-xl font-semibold mb-2 text-black'>{footerData.SubscribeNow.heading}</h1>
                            <p className=''>{footerData.SubscribeNow.description}</p>
                            <div className="flex mt-5">
                                <input className='border p-3 text-base w-full' type="email" placeholder='Enter your email' />
                                <span className='block p-3 rounded-lg bg-black text-white text-2xl'><IoIosSend/></span>
                            </div>
                        </div>
                </div>
                <div className='border-t text-sm p-5 text-center text-gray-600'>
                    <p>Copyright © 2024 Scrapcart.Pk - All Rights Reserved</p>
                </div>

        </div>
    </footer>
  )
}

export default Footer
