import React from 'react'
import { Link } from 'react-router-dom';
import { ImLink } from "react-icons/im";
import ahmed from '../assets/ahmed.png'
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import Ghazal from "../assets/ghazal.png"
import aliAslam from '../assets/aliAslam.png'
const ourTeam = [
    { img: aliAslam, name: 'Ali Aslam', role: 'CEO and Co-founder', desc: "Ali Aslam, Co-founder and CEO, leads Scrap Solutions with a vision for growth and innovation in the scrap industry.", fb: 'https://www.facebook.com/AhmedrAshrafDEV', linkedin: "https://www.linkedin.com/in/ahmedrashraf/", fbIcon: <FaFacebookF/>, linkedinIcon: <FaLinkedinIn/>, portfolio: <ImLink/> },
    { img: Ghazal, name: 'Muhammad Ghazal', role: 'Team Managing Head', desc: "Ghazal is a skilled front-end developer, specializes in React, React Native, and other modern technologies.", fb: 'https://www.facebook.com/AhmedrAshrafDEV', linkedin: "https://www.linkedin.com/in/ahmedrashraf/", fbIcon: <FaFacebookF/>, linkedinIcon: <FaLinkedinIn/>, portfolio: <ImLink/> },
    { img: ahmed, name: 'Ahmed Ashraf', role: 'Head Development Team', desc: "Ahmed is a skilled front-end developer, specializes in React, React Native, and other modern technologies.", fb: 'https://www.facebook.com/AhmedrAshrafDEV', linkedin: "https://www.linkedin.com/in/ahmedrashraf/", fbIcon: <FaFacebookF/>, linkedinIcon: <FaLinkedinIn/>, portfolio: <ImLink/> },
    // { img: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg', name: 'Ali Aslam', role: 'Team Manging Head', desc: "Ali Aslam, Co-founder and CEO, leads Scrap Solutions with a vision for growth and innovation in the scrap industry.", fb: 'https://www.facebook.com/AhmedrAshrafDEV', linkedin: "https://www.linkedin.com/in/ahmedrashraf/",  fbIcon: <FaFacebookF/>, linkedinIcon: <FaLinkedinIn/>, portfolio: <ImLink/> },
]
const MeetOurTeam = () => {
    return (
        <div className='container mx-auto p-6  my-10 md:my-20'>
            <div className='md:w-2/5 mx-auto text-center'>
                <h1 className='text-3xl font-semibold'>Meet our team</h1>
                <p className='text-gray-600 font-bold mt-3 font-thicccboiMedium'>Scrap Solutions delivers seamless, efficient scrap buying and selling with fast transactions and the best deals.</p>
            </div>

            <div className="flex md:flex-row flex-col justify-around items-center gap-7 mt-10">
                {ourTeam.map((service, index)=>(
                    <div key={index} className='relative bg-gray-50 rounded-xl text-center shadow font-thicccboiMedium'>
                        <img className='mx-auto rounded-md object-cover h-96' src={service.img} alt="team member" />
                        <div className='absolute bottom-0 w-full flex flex-col opacity-80 justify-center p-3 bg-white'>
                                <h2 className='font-semibold text-2xl'>{service.name}</h2>
                                <p className='font-bold text-lg text-gray-600'>{service.role}</p>
                                <div className="flex mt-4 justify-center gap-5 items-center">
                                    <div className='text-base p-2 rounded-lg bg-black text-white'>
                                        <Link to={service.fb}>{service.fbIcon}</Link>
                                    </div>
                                    <div className='text-base p-2 rounded-lg bg-black text-white'>
                                        <Link to={service.linkedin}>{service.linkedinIcon}</Link>
                                    </div>
                                    <div className='text-base p-2 rounded-lg bg-black text-white'>
                                        <Link to={service.portfolio}>{service.portfolio}</Link>
                                    </div>
                                </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MeetOurTeam
