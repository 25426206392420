import React from 'react'
import BannerVideo from "../assets/banner-video.mp4"

const Banner = ({id}) => {
  return (
<div id={id} className='relative h-[80vh]'>
    <video loop muted autoPlay playsinline preload="auto" class="w-full h-full object-cover">
        <source src={BannerVideo} type="video/mp4"/>
    </video>
    <div className="absolute inset-0 top-0 bg-black opacity-70"></div>

        {/* banner content */}
    <div className='absolute top-0 z-10 h-full flex flex-col items-center justify-center text-white p-3 md:max-w-3xl left-0 m-auto right-0 text-center'>
        <h1 class="md:text-6xl text-4xl leading-tight">Efficient Scrap Buying & Selling Solutions</h1>
        <p className='md:text-xl md:w-3/4 my-3 md:my-10 mx-auto font-thicccboiMedium'>Turn your scrap into cash! Buy, sell, and support a sustainable future with us today!</p>
            <div className="flex justify-center items-center mt-5 gap-4">
                <button className='p-3 px-5 rounded-2xl border-2 font-thicccboiMedium transition-all ease-in-out backdrop-blur-sm'>Download App</button>
                <button className='p-3 px-5 rounded-2xl border-2 font-thicccboiMedium transition-all ease-in-out backdrop-blur-sm'>Call Agent</button>
            </div>
    </div>
</div>
  )
}

export default Banner
