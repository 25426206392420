import React from 'react'
import Bin from '../assets/bin.jpg'
import { Link } from 'react-router-dom'

const CommitmentToQuality = () => {
  return (
    <div className='p-8 md:my-24  my-10 text-center md:text-start relative'>
    <div className='container mx-auto flex md:flex-row flex-col-reverse justify-between items-center gap-10'>
        <div className='md:w-2/5'>
            <h1 className='text-3xl leading-tight  font-semibold'>Our Commitment to Sustainability & the Circular Economy</h1>
            <p className='font-thicccboiMedium my-7'>At ScrapCart, we focus on driving sustainability by efficiently managing scrap. We contribute to the circu</p>
                <div className="flex gap-5">
                    <button className='px-6 py-2 font-thicccboiMedium border-green text-green  rounded-2xl border ease-in-out duration-300'><Link>Learn more</Link></button>
                    <button className='px-6 py-2 font-thicccboiMedium border-green text-green  rounded-2xl border ease-in-out duration-300'><Link>Downlaod App</Link></button>
                </div>
        </div>
        <div className='md:w-2/4 h-96'>
            <img className='rounded-full h-full w-full object-cover' src={Bin} alt="Bin image" />
        </div>
    </div>
    </div>
  )
}

export default CommitmentToQuality
