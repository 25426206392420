import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules'; 
import { FaLeaf, FaRecycle, FaGlobe, FaTree, FaSun, FaWater, FaHandsHelping, FaWind } from 'react-icons/fa'; 

const GreenScroll = () => {    

    const messages = [
        { id: 1, content: "Eco-friendly the planet", icon: <FaLeaf /> },
        { id: 2, content: "Conserve the planet", icon: <FaRecycle /> },
        { id: 3, content: "Save the planet", icon: <FaGlobe /> },
        { id: 4, content: "Environmentally-friendly", icon: <FaTree /> },
        { id: 5, content: "Green the planet", icon: <FaSun /> },
        { id: 6, content: "Reduce water waste", icon: <FaWater /> },  
        { id: 7, content: "Protect wildlife", icon: <FaHandsHelping /> }, 
        { id: 8, content: "Use renewable energy", icon: <FaSun /> },
        { id: 9, content: "Harness wind power", icon: <FaWind /> }, 
        { id: 10, content: "Go plastic-free", icon: <FaRecycle /> },
    ];

    
  return (
    <div className='flex gap-10 py-12 p-2 md:p-5 justify-center items-center'>
    <Swiper pagination={{ clickable: true }} centeredSlides={true}loop={true} spaceBetween={40} modules={[Autoplay]} autoplay={{ delay: 1000, disableOnInteraction: false }}
       breakpoints={{
        1920: { slidesPerView: 5,spaceBetween: 30,},
         990: {slidesPerView: 4,spaceBetween: 20,},
        280: {slidesPerView: 2,spaceBetween: 10,},
        }}>
            {messages.map((item,index) => (
                <SwiperSlide key={index} className='font-thicccboiMedium justify-center flex gap-3 md:gap-5 items-center text-sm md:text-lg'>
                    <span className='md:text-2xl text-green '>{item.icon}</span> {item.content}
                </SwiperSlide>
            ))}
        </Swiper>
  </div>
  )
}

export default GreenScroll
