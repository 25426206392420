import React from 'react'
import Mockup from '../assets/mobileMockup.png'
import AppStore from '../assets/AppStore.png'
import PlayStore from '../assets/playStore.webp'
import { FaRecycle, FaMoneyBillWave, FaMobileAlt } from 'react-icons/fa'

const DownloadApp = () => {
    return (
        <div className='flex md:flex-row flex-col items-center gap-10 justify-between p-8 md:py-20 py-8 container mx-auto'>
            <div className='md:md:w-2/5'>
                <h1 className='text-3xl font-Merriweather leading-snug'>Manage and Sell Scrap Effortlessly—Download Our App!</h1>
                <p className='my-5 text-gray-600 font-thicccboiMedium text-bold'>Download our app to easily manage and sell your scrap. Quick, simple, and efficient—get started today!</p>
                <div>
                    <p className='flex gap-3 font-medium mt-5 md:w-3/4 font-thicccboiMedium p-3 shadow items-center rounded-lg'>- Easy scrap categorization and pricing</p>
                    <p className='flex gap-3 font-medium mt-5 md:w-3/4 font-thicccboiMedium p-3 shadow items-center rounded-lg'>- Get instant quotes for your scrap</p>
                    <p className='flex gap-3 font-medium mt-5 md:w-3/4 font-thicccboiMedium p-3 shadow items-center rounded-lg'>- Hassle-free transactions with verified vendors</p>
                </div>
                <div className="flex gap-5 justify-start mt-7">
                    <button className='md:px-6 px-2 w-2/5 font-thicccboiMedium flex p-2 justify-center items-center gap-2 border rounded-2xl'>
                        <img src={AppStore} className='w-10' alt="app store" />
                        <a href="">Play Store</a>
                    </button>
                    <button className='md:px-6 px-2 w-2/5 font-thicccboiMedium flex justify-center items-center gap-2 p-2 border rounded-2xl'>
                        <img src={PlayStore} className='w-8' alt="app store" />
                        <a href="">Play Store</a>
                    </button>
                </div>
            </div>

            <div className='md:w-2/5'>
                <img src={Mockup} className='w-full' alt="Download app" />
            </div>
        </div>
    )
}

export default DownloadApp
