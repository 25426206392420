import React from 'react'
import { FaFireAlt, FaUsers, FaBalanceScale, FaTruck, FaMobileAlt, FaCreditCard } from 'react-icons/fa';
import RecyclingImg from "../assets/scrap-recyling.webp";

const services = [
    { icon: <FaFireAlt/>, title: "Seamless Pickup Services", desc: "Schedule doorstep pickups at your convenience, saving you time and effort." },
    { icon: <FaUsers/>, title: "Eco-Friendly Commitment", desc: "We support sustainable practices, reducing waste and promoting responsible scrap management." },
    { icon: <FaBalanceScale/>, title: "Accurate Weight", desc: "Get the best value for your materials by connecting with verified vendors and top bidders." },
    { icon: <FaTruck/>, title: "Top Prices for Your Scrap", desc: "All our vendors are carefully vetted to ensure a hassle-free and secure transaction." },
    { icon: <FaMobileAlt/>, title: "Trusted and Reliable Network", desc: "All our vendors are carefully vetted to ensure a hassle-free and secure transaction." },
    // { icon: <FaCreditCard/>, title: "de", desc: "Our intuitive app and website make scheduling pickups and selling scrap quick and hassle-free." },
];

const WhyUs = ({id}) => {
  return (
    <div id={id} className="container mx-auto p-8  my-10 md:my-28 md:text-start flex justify-between md:flex-row flex-col gap-10">
        <div className='md:w-2/4'>
        <img src={RecyclingImg} alt="Recyclin img" className='w-full object-cover rounded-md' />
        </div>

        <div className='md:w-2/5'>
            <h1 className='text-3xl font-semibold'>Why Choose Us</h1>
            <p className='text-gray-600 font-semibold my-5 font-thicccboiMedium'>At Scrapcart, we deliver top-notch services and outstanding results. Here's why you should choose us.</p>
            {services.map((service, index)=>(
                <div key={index} className='p-2 mt-3 flex gap-2 items-center'>
                        <div className='p-3 text-3xl'>
                            {service.icon}
                        </div>
                        <div>
                            <h1 className='font-thicccboiMedium text-lg my-1'>{service.title}</h1>
                            <p className='text-sm font-thicccboiMedium text-gray-600'>{service.desc}</p>
                    </div>
                </div>
            ))}
            </div>
    </div>
  )
}

export default WhyUs
